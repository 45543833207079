$(function () {
    initNav();
    initSearch();
    initObjectCarousel();
    initServiceCarousel();
    initSocialCarousel();
    initDropdown();
    initObject();
    initContact();
    initMatchHeight();
});

$(window).on('load', function () {

});

$(window).on('resize', function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
    }, 200));
});

function initNav() {
    let $nav = $('header nav');
    $('header .burger').unbind();
    $nav.find('a').has('ul').unbind();
    $nav.find('ul').attr('style', false);

    if ($(window).outerWidth() < 992) {
        $('header .burger').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('nav-open');
        });

        $nav.find('a + ul').each(function (i, ul) {
            let $ul = $(ul);
            let $a = $(ul).prev('a');
            if ($a.hasClass('active')) {
                $ul.show();
            }
            $a.on('click', function (e) {
                if (!$ul.is(':visible')) {
                    e.preventDefault();
                    $nav.find('> ul > li ul:visible').slideUp().prev('a').removeClass('active');
                }
                $a.addClass('active');
                $ul.slideDown();
            });
        });
    } else {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 0) {
                $('body').addClass('nav_fixed');
            } else {
                $('body').removeClass('nav_fixed');
            }
        });
    }
}

function initSearch() {
    if ($('.search_wrapper').length > 0) {

        $('#search_toggler').on('click', function (e) {
            e.preventDefault();
            $(this).hide();
            $('.search_wrapper').find('form').fadeIn();

            $('html, body').animate({
                scrollTop: $('.search_wrapper').offset().top - 120
            });
        });

        $('.fake_input').each(function (i, el) {
            let that = $(el);
            let input = $(el).find('input[type=hidden]');
            let content = $(el).find('.content');
            let ul = that.next();

            that.on('click', function () {
                $('.fake_input').each(function(i, tmp){
                    if(ul[0] !== $(tmp).next()[0]) {
                        $(tmp).next().fadeOut(100);
                    }
                });

                ul.fadeToggle(100);
            });

            ul.find('li').on('click', function () {
                input.val($(this).data('value')).trigger('change');
                content.html($(this).text());
                ul.fadeOut(100);
            });

            $(document).click(function() {
                ul.fadeOut(100);
            });

            ul.parent().on('click', function(event) {
                event.stopPropagation();
            });
        });

        $('input[name="koophuur"]').on('change', function () {
            if ($(this).val() == 'koop') {
                $('.wrapper_prijs_koop').show();
                $('.wrapper_prijs_koop').find('input').prop('disabled', false);
                $('.wrapper_prijs_huur').hide();
                $('.wrapper_prijs_huur').find('input').prop('disabled', true);
            } else if ($(this).val() == 'huur') {
                $('.wrapper_prijs_koop').hide();
                $('.wrapper_prijs_koop').find('input').prop('disabled', true);
                $('.wrapper_prijs_huur').show();
                $('.wrapper_prijs_huur').find('input').prop('disabled', false);
            }
        });

        $('li[data-selected=1]').each(function (i, el) {
            console.log(el);
            $(el).click();
        });

        $('input[name="koophuur"]').change();
    }
}

function initObjectCarousel() {
    if ($('#object_carousel').length > 0) {
        $('#object_carousel').slick({
            dots: false,
            arrows: false,
            infinite: false,
            initialSlide: 0,
            slidesToScroll: 1,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        initialSlide: 0,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        initialSlide: 1,
                        slidesToShow: 1
                    }
                }
            ]
        }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $nextSlide = $(slick.$slides[nextSlide]);
            $nextSlide.removeClass('slick-previous');
            $nextSlide.prevAll().addClass('slick-previous');

            if (nextSlide == 0) {
                $('.carousel-prev').addClass('inactive');
            } else {
                $('.carousel-prev').removeClass('inactive');
            }

            if (nextSlide == $('#object_carousel').slick('getSlick').slideCount - 2) {
                $('.carousel-next').addClass('inactive');
            } else {
                $('.carousel-next').removeClass('inactive');
            }
        });

        $('.carousel-prev').on('click', function (e) {
            e.preventDefault();
            $('#object_carousel').slick('slickPrev');
        })

        $('.carousel-next').on('click', function (e) {
            e.preventDefault();
            $('#object_carousel').slick('slickNext');
        })
    }
}

function initServiceCarousel() {
    if ($('#service_carousel').length > 0) {
        $('#service_carousel').slick({
            dots: false,
            arrows: false,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initSocialCarousel() {
    if ($('#social_carousel').length > 0) {
        $('#social_carousel').slick({
            dots: false,
            arrows: false,
            infinite: false,
            slidesToScroll: 1,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initDropdown() {
    $('.dropdown').each(function (i, el) {
        $(el).on('click', function () {
            $(el).find('ul').fadeToggle(150);

            $(el).find('a').on('click', function (e) {
                $(el).find('.value').text($(this).text());
            });
        });
    });
}

function initObject() {
    if ($('#object').length > 0) {
        // Button
        $('#omschrijving + a').on('click', function (e) {
            e.preventDefault();

            $('#omschrijving').addClass('show');
            $(this).hide();
        });

        // Lightbox
        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }

        initObjectPano();

        // Gallery icon
        $('.icon-images').on('click', function (e) {
            e.preventDefault();
            $('.lightbox').first().click();
        });
    }
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}
